import React from 'react';
import './css/Button.css';

const CustomButton = ({
  type = '',
  text,
  onClick,
  color = '#56bd82',
  disabled = false,
}) => {
  return (
    <button
      type={type}
      className={'button-container'}
      style={{ background: disabled ? '#C4C4c4' : color }}
      onClick={onClick}
      disabled={disabled}
    >
      <span className='button-text'>{text}</span>
    </button>
  );
};

export default CustomButton;
