import React, { useEffect, useState } from "react";
import "./css/Categories.css";
import Sync from "./svgs/Cat";
import DefaultCategories from "./data/Categories";
import { Col, Row } from "react-bootstrap";
import CategoryItem from "./CategoryItem";
import useWindowDimensions from "./hooks/useWindowDimensions";

const Categories = () => {
  const { width } = useWindowDimensions();

  const [col, setCol] = useState(5);

  useEffect(() => {
    if (width <= 430) {
      setCol(1);
    } else if (width < 1024) {
      setCol(2);
    } else if (width <= 1440) {
      setCol(4);
    }  else if (width > 1440) {
      setCol(5);
    }

  }, [width]);

  const Cats = () => {
  
    const row = Math.round(DefaultCategories.length / col);


    return (
      <div>
        {[...Array(row)].map((el, i) => (
          <Row>
            {[...Array(col)].map((el_j, j) => (
              <Col>
                <CategoryItem item={DefaultCategories[i * col + j]} />
              </Col>
            ))}
          </Row>
        ))}
      </div>
    );
  };

  return (
    <div className="item-container-5">
      <div className="title-wrapper">
        <div className="icon-wrapper">
          <Sync />
        </div>
        <p className="title">Categories</p>
      </div>
      <div
        className="component-card"
        style={{ height: "auto", paddingBottom: 50 }}
      >
        <p className="subtitle">
          These are the categories available on Trakka{" "}
        </p>

        <Cats />
      </div>
    </div>
  );
};

export default Categories;
