export default {
    Housing: '/assets/categories/housing.png',
    Transportation: '/assets/categories/transportation.png',
    Food: '/assets/categories/food.png',
    "Utilities & Bills": '/assets/categories/bills.png',
    Healthcare: '/assets/categories/health.png',
    Savings: '/assets/categories/saving.png',
    "Gifts & Donations": '/assets/categories/gift.png',
    Entertainment: '/assets/categories/entertainment.png',
    Miscellaneous: '/assets/categories/misc.png',
    Shopping: '/assets/categories/shopping.png',
    Investment: '/assets/categories/invest.png',
    Subscrpition: '/assets/categories/sub.png',
    "Phone and Internet": '/assets/categories/phone.png',
    Transfer: '/assets/categories/transfer.png',
    Withdrawal: '/assets/categories/withdrawal.png',
    Travel: '/assets/categories/travel.png',
    "Income & Earnings": '/assets/categories/income.png',
    Education: '/assets/categories/education.png',
    Loan: '/assets/categories/loan.png',
    Uncategorized: '/assets/categories/uncategorized.png',
    Others : '/assets/categories/other.png',
    Credit : '/assets/categories/credit.png',
    "Bank Charges" :  '/assets/categories/charges.png',
    "Fuel" :  '/assets/categories/fuel.png',
}