import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from './CustomButton';
import CustomInput from './CustomInput';
import API from './data/API';

const LogInBox = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [disabled, setDisabled] = useState(true);
  const [buttonText, setButtonText] = useState('Login');

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleEmail = (e) => {
    setButtonText('Login');
    setDisabled(false);
    setEmail(e.target.value);

    if (e.target.value === '') {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handlePass = (e) => {
    setButtonText('Login');
    setDisabled(false);
    setPassword(e.target.value);

    if (e.target.value === '') {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const req = {
      password: password,
      username: email,
    };

    if (password === '') {
      setPasswordError(true);
      return;
    } else if (email === '') {
      setEmailError(true);
      return;
    } else {
    }

    try {
      setButtonText('Loading...');
      setDisabled(true);
      const res = await axios.post(API.login, req);

      const uid = res.data.uid;
      const token = res.data.token;

      const now = Date.now();

      // var safeUrl = `http://localhost:3000?user=${uid}&token=${token}&tl=${now}`;
      var safeUrl = `https://account.trakka.me?user=${uid}&token=${token}&tl=${now}`;

      window.open(safeUrl, '_self');
    } catch (err) {
      setDisabled(true);
      setButtonText(err.response.data[0]);
    }
  };

  //   document.getElementById("#password").on('keyup', function (event) {
  //     if (event.key === 13) {
  //        console.log("Enter key pressed!!!!!");
  //     }
  //  });

  return (
    <div
      className='item-container-2'
      style={{
        maxWidth: '650px',
        alignSelf: 'center',
      }}
    >
      <div
        className='component-card-2  '
        style={{
          height: '100%',
        }}
      >
        <p className='subtitle' style={{ paddingBottom: 0, marginBottom: 20 }}>
          Login to your Trakka account
        </p>
        <form onSubmit={handleSubmit}>
          <CustomInput
            label={'Email'}
            value={email}
            onChange={handleEmail}
            error={emailError}
            errorText={'Email is required'}
          />

          <CustomInput
            id='password'
            label={'Password'}
            value={password}
            onChange={handlePass}
            placeholder={'****************'}
            password={true}
            error={passwordError}
            errorText={'Password is required'}
          />

          <div
            style={{
              display: 'flex',
              marginTop: 50,
              marginBottom: 30,
              justifyContent: 'center',
            }}
          >
            <CustomButton
              type='submit'
              text={buttonText}
              disabled={disabled || emailError || passwordError}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogInBox;
