import React from "react";

function Sync() {
  return (
    <svg
    className = "icon"
      xmlns="http://www.w3.org/2000/svg"
     
      fill="none"
      viewBox="0 0 50 50"
    >
      <circle cx="25" cy="25" r="25" fill="#56BD82"></circle>
      <path
        fill="#FAFAFA"
        d="M21.68 35.478a7.156 7.156 0 01-5.073-2.096c-2.79-2.801-2.79-7.345 0-10.136.32-.32.849-.32 1.169 0 .32.32.32.85 0 1.17a5.518 5.518 0 000 7.797 5.518 5.518 0 007.797 0 5.488 5.488 0 001.61-3.905 5.527 5.527 0 00-1.61-3.904.832.832 0 010-1.169c.32-.32.85-.32 1.17 0a7.115 7.115 0 012.095 5.073 7.16 7.16 0 01-2.096 5.074 7.094 7.094 0 01-5.062 2.096z"
      ></path>
      <path
        fill="#FAFAFA"
        d="M32.798 27.382a.818.818 0 01-.585-.242.832.832 0 010-1.17c2.261-2.26 2.261-5.933 0-8.183-2.26-2.261-5.934-2.261-8.184 0a5.757 5.757 0 00-1.698 4.091c0 1.545.607 3 1.698 4.092.32.32.32.85 0 1.17-.32.32-.849.32-1.169 0a7.41 7.41 0 01-2.183-5.262c0-1.985.772-3.86 2.183-5.26 2.901-2.901 7.622-2.901 10.533 0 2.901 2.9 2.901 7.632 0 10.533a.881.881 0 01-.595.231z"
      ></path>
    </svg>
  );
}

export default Sync;
