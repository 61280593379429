import React, { useEffect, useState } from "react";
import BanksMapping from "./constants/BanksMapping";
import "./css/BankItem.css";
import useWindowDimensions from "./hooks/useWindowDimensions";
import Icon from "./svgs/Delete";
function truncateString(str, num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "...";
}

function numberWithCommasAnd2dp(x) {
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const AccountItem = ({ account }) => {
  const { width } = useWindowDimensions();

  const [truncLength, setTruncLength] = useState(6);

  useEffect(() => {
      if (width <= 430 ) {
      setTruncLength(30);
    } else if (width <= 1024) {
      setTruncLength(12);
    } else if (width > 1024) {
      setTruncLength(20);
    } 
  }, [width]);

  const [touched, touchedSet] = React.useState(false);

  let acc = account;

  let img = BanksMapping[acc.institution];

  return (
    <div>
      <div className="account-item-container">
        <img
          src={img.image}
          style={{ borderColor: img.line }}
          className="bank-img"
        />

        <p className="inner-text bank-name">
          {truncateString(acc.institution, truncLength)}
        </p>

        <p className="inner-text account-number">{acc.acocunt_number}</p>

        <p className="inner-text account-name">
          {truncateString(acc.account_name, truncLength)}
        </p>

        <p className="inner-text balance ">
          ₦ {numberWithCommasAnd2dp(parseFloat(acc.balance))}
        </p>
{/* 
        <button
          style={{
            opacity: touched ? 0.2 : 1,
            transition: "opacity 300ms ease",
            borderWidth: 0,
            backgroundColor: "white",
          }}
          onMouseDown={() => touchedSet(true)}
          onMouseUp={() => touchedSet(false)}
        >
          <Icon />
        </button> */}
      </div>
      <hr
        style={{
          color: "#CCCCCC",
          backgroundColor: "#CCCCCC",
          height: 2,
          margin: "12px",
        }}
      />
    </div>
  );
};

export default AccountItem;
