import React, { useRef, useState } from "react";
import "./css/CustomInput.css";
import Eye from "./svgs/Eye";
import EyeSlash from "./svgs/EyeSlash";

const CustomInput = ({
  defaultValue,
  label,
  placeholder,
  value,
  editable = true,
  onChange,
  password = false,
  error = false,
  errorText,
}) => {
  const ref = useRef();
  const [hidden, setHidden] = useState(password);
  const flipHidden = () => {
    setHidden(!hidden);
  };
  return (
    <div className="text-input-outer" 
    >

      <div
        className="text-input-wrapper"
        style={{
          display: "flex",
          flexDirection: "row",
          borderWidth: error ? 1 : 0,
          borderColor: "red",
        }}
        onClick={() => {
          ref.current.focus();
        }}
        
      >
        <div className="text-input-container">
          <div style={{ textAlign: "left", paddingLeft: 20 }}>
            <span className="input-label">{label}</span>
          </div>
          <div style={{ textAlign: "left", paddingLeft: 20 }}>
            <input
              className="input-box"
              placeholder={placeholder}
              value={value || ""}
              defaultValue={defaultValue}
              disabled={!editable}
              ref={ref}
              onChange={onChange}
              type={hidden ? "password" : "text"}
            />
          </div>
        </div>
        {password ? (
          hidden ? (
            <div onClick={flipHidden}>
              <Eye />
            </div>
          ) : (
            <div onClick={flipHidden}>
              <EyeSlash />
            </div>
          )
        ) : null}
      </div>
      {error ? <p className="error-text">{errorText}</p> : null}
    </div>
  );
};

export default CustomInput;
