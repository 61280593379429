import axios from "axios";
import React, { useState } from "react";
import "./css/ChangePassword.css";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import API from "./data/API";
import Icon from "./svgs/Password";

const ChangePassword = () => {
  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();

  const [confirmPass, setConfirmPass] = useState();
  const [disabled, setDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Update Password");

  const [newPassError, setNewPassError] = useState(false);
  const [confPassError, setConfPassError] = useState(false);
  const [oldPassError, setOldPassError] = useState(false);

  const handleOldPass = (e) => {
    setButtonText("Update Password");
    setDisabled(false);
    setOldPass(e.target.value);

    if (e.target.value === "") {
      setOldPassError(true);
    } else {
      setOldPassError(false);
    }
  };

  const handleNewPass = (e) => {
    setButtonText("Update Password");
    setDisabled(false);
    setNewPass(e.target.value);

    const reg = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");

    if (!reg.test(e.target.value)) {
      setNewPassError(true);
    } else {
      setNewPassError(false);
    }
  };

  const handleConfirmPass = (e) => {
    setButtonText("Update Password");
    setDisabled(false);
    setConfirmPass(e.target.value);

    if (e.target.value !== newPass) {
      setConfPassError(true);
    } else {
      setConfPassError(false);
    }
  };

  const handleSubmit = async () => {
    const req = {
      newpassword: newPass,
      password: oldPass,
    };

    try {
      setButtonText("Loading...");
      setDisabled(true);

      const res = await axios.post(API.changePassword, req);
      setButtonText("Password Changed");
      setDisabled(true);
    } catch (err) {
      setDisabled(true);
      setButtonText("Somthing went wrong");
    }
  };

  return (
    <div className="item-container-2">
      <div className="title-wrapper-2">
        <div className="icon-wrapper">
          <Icon />
        </div>
        <p className="title">Change Password</p>
      </div>
      <div className="component-card-2 card-purple ">
        <p className="subtitle" style={{ paddingBottom: 0, marginBottom: 20 }}>
          Change your password using the form below
        </p>

        <CustomInput
          label={"Old Password"}
          value={oldPass}
          onChange={handleOldPass}
          placeholder={"****************"}
          password={true}
          error={oldPassError}
          errorText={"Old password is required"}
        />

        <CustomInput
          label={"New Password"}
          value={newPass}
          onChange={handleNewPass}
          placeholder={"****************"}
          password={true}
          error={newPassError}
          errorText={
            "Minimum 8 characters, at least an uppercase letter, a lowercase letter and a number"
          }
        />

        <CustomInput
          label={"Confirm Password"}
          value={confirmPass}
          onChange={handleConfirmPass}
          placeholder={"****************"}
          password={true}
          error={confPassError}
          errorText={"Passwords don't match"}
        />
        <div
          style={{ display: "flex", marginTop: 20, justifyContent: "center" }}
        >
          <CustomButton
            text={buttonText}
            disabled={disabled || newPassError || confPassError || oldPassError}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
