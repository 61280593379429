import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className = "icon"
      fill="none"
      viewBox="0 0 50 50"
    >
      <circle cx="25" cy="25" r="25" fill="#56BD82"></circle>
      <path
        fill="#FAFAFA"
        d="M25 25.827a6.35 6.35 0 01-6.342-6.342A6.35 6.35 0 0125 13.144a6.35 6.35 0 016.342 6.341A6.35 6.35 0 0125 25.827zm0-11.029a4.698 4.698 0 00-4.688 4.687A4.698 4.698 0 0025 24.173a4.698 4.698 0 004.687-4.688A4.698 4.698 0 0025 14.798zM29.213 36.856a1.55 1.55 0 01-1.103-.44c-.342-.343-.496-.84-.419-1.357l.21-1.49c.055-.385.287-.838.562-1.124l3.905-3.905c1.566-1.566 2.944-.673 3.617 0 .574.574.872 1.191.872 1.809 0 .629-.287 1.213-.872 1.798l-3.904 3.904a2.183 2.183 0 01-1.125.563l-1.49.21a1 1 0 01-.253.032zm4.952-7.533c-.198 0-.375.133-.628.386l-3.905 3.905a.42.42 0 00-.088.187l-.198 1.379 1.378-.199a.612.612 0 00.188-.099l3.904-3.904c.177-.177.386-.43.386-.629 0-.166-.132-.397-.386-.64-.265-.264-.463-.386-.65-.386z"
      ></path>
      <path
        fill="#FAFAFA"
        d="M34.838 32.963a.71.71 0 01-.22-.033 4.387 4.387 0 01-3.022-3.022.821.821 0 01.573-1.014.821.821 0 011.015.573 2.72 2.72 0 001.875 1.875.826.826 0 01-.22 1.621zM15.526 36.856a.833.833 0 01-.827-.827c0-4.71 4.621-8.548 10.301-8.548 1.202 0 2.394.177 3.508.508a.82.82 0 01.551 1.026.82.82 0 01-1.026.551A10.676 10.676 0 0025 29.125c-4.765 0-8.647 3.088-8.647 6.893a.836.836 0 01-.827.838z"
      ></path>
    </svg>
  );
}

export default Icon;