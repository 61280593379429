import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/Header";
import LogInBox from "../components/LogInBox";

const Login = () => {
  return (
    <div>
      <Header />
      <Container
        className="content-body"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LogInBox />
      </Container>
    </div>
  );
};

export default Login;
