import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className = "icon"
      fill="none"
      viewBox="0 0 50 50"
    >
      <circle cx="25" cy="25" r="25" fill="#56BD82"></circle>
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M13.97 21.145h22.06M18.382 29.969h2.206M23.346 29.969h4.411"
      ></path>
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.867 15.63h12.254c3.927 0 4.908.971 4.908 4.842v9.055c0 3.872-.981 4.842-4.897 4.842H18.867c-3.915.011-4.897-.96-4.897-4.83v-9.067c0-3.871.982-4.842 4.897-4.842z"
      ></path>
    </svg>
  );
}

export default Icon;