import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className = "icon"
      fill="none"
      viewBox="0 0 50 50"
    >
      <circle cx="25" cy="25" r="25" fill="#56BD82"></circle>
      <path
        fill="#FAFAFA"
        d="M25 36.867c-1.202 0-2.393-.352-3.33-1.047l-4.744-3.54c-1.257-.938-2.238-2.89-2.238-4.457v-8.206c0-1.698 1.246-3.507 2.845-4.102l5.504-2.063c1.092-.408 2.812-.408 3.904 0l5.515 2.063c1.6.595 2.845 2.404 2.845 4.102v8.195c0 1.566-.981 3.519-2.239 4.456l-4.742 3.54c-.927.707-2.118 1.06-3.32 1.06zm-1.379-21.86l-5.503 2.063c-.949.353-1.776 1.544-1.776 2.558v8.195c0 1.048.739 2.515 1.566 3.133l4.743 3.54c1.268.949 3.419.949 4.687 0l4.743-3.54c.838-.629 1.566-2.096 1.566-3.133v-8.206c0-1.003-.827-2.194-1.776-2.558l-5.503-2.063c-.728-.264-2.008-.264-2.747.011z"
      ></path>
      <path
        fill="#FAFAFA"
        d="M25 26.379a3.031 3.031 0 01-3.033-3.033A3.031 3.031 0 0125 20.312a3.031 3.031 0 013.033 3.034A3.031 3.031 0 0125 26.379zm0-4.412A1.38 1.38 0 1025 24.725 1.38 1.38 0 0025 21.967z"
      ></path>
      <path
        fill="#FAFAFA"
        d="M25 29.687a.833.833 0 01-.827-.827v-3.309c0-.452.375-.827.827-.827.452 0 .827.375.827.827v3.31a.833.833 0 01-.827.826z"
      ></path>
    </svg>
  );
}

export default Icon;