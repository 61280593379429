import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./css/SyncedAccounts.css";
import CustomInput from "./CustomInput";
import Icon from "./svgs/Profile";
import CustomButton from "./CustomButton";
import axios from "axios";
import API from "./data/API";

const Profile = ({ userDetails }) => {
  const [disabled, setDisabled] = useState(true);

  const [firstName, setFirstName] = useState(userDetails.firstname);
  const [lastName, setLastName] = useState(userDetails.lastname);
 
const [buttonText, setButtonText] = useState("Save Changes")
  const handleFirstName = (e) => {
    setButtonText("Save Changes")
    setDisabled(false)
    setFirstName(e.target.value.trim())
  }

  const handleLastName = (e) => {
    setButtonText("Save Changes")
    setDisabled(false)
    setLastName(e.target.value.trim())
  }

  const handleSubmit = async () => {
    const req = {
      firstname : firstName,
      lastname : lastName
    }

    try {
      setButtonText("Loading..");
      setDisabled(true);
      const res = await axios.post(API.updateProfile, req)
      setButtonText('Profile Saved')
      setDisabled(true)
    }  catch(err) {
      setButtonText('Somthing went wrong')
    }
  }

  return (
    <div className="item-container">
      <div className="title-wrapper">
        <div className="icon-wrapper">
          <Icon />
        </div>
        <p className="title">Edit Profile</p>
      </div>
      <div className="component-card sub-card">
        <p className="subtitle">
          You can only change your name with this form. Contact us at
          support@trakka.me to edit account information.
        </p>
        <Row>
          <Col l={6} md={6} sm = {6}>
            {" "}
            <CustomInput
              label={"Firstname"}
              value={firstName}
              onChange={handleFirstName}
            />{" "}
          </Col>
          <Col l={6} md={6} sm = {6}>
            <CustomInput
              label={"Lastname"}
              value={lastName}
              onChange={handleLastName}
            />
          </Col>
        </Row>
        <Row>
          <Col l={6} md={6} sm = {6}>
            <CustomInput
              label={"Email"}
              value={userDetails.email}
              editable={false}
            />
          </Col>
          <Col l={6} md={6} sm = {6}>
            <CustomInput
              label={"Phone number"}
              value={userDetails.phone}
              editable={false}
            />
          </Col>
        </Row>
        <div
          style={{ display: "flex", marginTop: 20, justifyContent: "center" }}
        >
          <CustomButton text={buttonText} disabled={disabled} onClick = {handleSubmit}/>
        </div>
      </div>
    </div>
  );
};

export default Profile;
