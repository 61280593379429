const Banks = {
    "Access Bank" : {
        image : '/assets/BankLogos/access.png',
        primary : "#FFF4EE",
        line : '#FFC8A8'
    },
    Paystack : {
        image : '/assets/BankLogos/paystack.png',
        primary : "#ECF9FE",
        line : '#A0E2FA'
    }, 
    Flutterwave : {
        image : '/assets/BankLogos/flutterwave.png',
        primary : "#FEF9EF",
        line : '#FBDFB1'
    },

    'United Bank For Africa' : {
        image : '/assets/BankLogos/uba.png',
        primary : "#FDEEEE",
        line : '#F5A9AB'
    },
   
    "First Bank" : {
        image : '/assets/BankLogos/firstbank.png',
        primary : "#E8EFF8",
        line : '#8DB0DE'
    },
    "ALAT by WEMA" : {
        image : '/assets/BankLogos/alat.png',
        primary : "#FFE9EC",
        line : '#FF90A1'
    },
    "Ecobank Nigeria" : {
        image : '/assets/BankLogos/ecobank.png',
        primary : "#E3F7FF",
        line : '#75D8FF'
    },

    "Zenith Bank" : {
        image : '/assets/BankLogos/zenith.png',
        primary : "#FEEFEF",
        line : '#F8AEB1'
    },

    Piggyvest : {
        image : '/assets/BankLogos/piggyvest.jpg',
        primary : "#E8F0FE",
        line : '#8BB3F9'
    },


    "Keystone Bank" : {
        image : '/assets/BankLogos/keystone.jpg',
        primary : "#E8F1FC",
        line : '#8BB8EE'
    },

    FCMB : {
        image : '/assets/BankLogos/fcmb.png',
        primary : "#F4ECFA",
        line : '#C8A0E4'
    },

    KudaBank : {
        image : '/assets/BankLogos/kuda.png',
        primary : "#F0ECF8",
        line : '#B6A0DD'
    },
    "Wema Bank" : {
        image : '/assets/BankLogos/wema.jpg',
        primary : "#FDE9FA",
        line : '#F58FE4'
    },

    "Stanbic IBTC Bank" : {
        image : '/assets/BankLogos/stanbic.jpg',
        primary : "#E5F3FF",
        line : '#80C4FF'
    },
    Barter : {
        image : '/assets/BankLogos/barter.jpg',
        primary : "#FEF9EF",
        line : '#FBDFB1'
    },
    "Sterling Bank" : {
        image : '/assets/BankLogos/sterling.png',
        primary : "#FCF0F1",
        line : '#F2B6B8'
    },

    "Union Bank of Nigeria" : {
        image : '/assets/BankLogos/union.png',
        primary : "#ECFAFF",
        line : '#A1E5FE'
    },
    Brass : {
        image : '/assets/BankLogos/brass.jpg',
        primary : "#F2F6FF",
        line : '#BFD1FF'
    },
    "Jaiz Bank" : {
        image : '/assets/BankLogos/jaiz.png',
        primary : "#E9F8EF",
        line : '#92DCB1'
    },

    "VULTe Digital Bank" : {
        image : '/assets/BankLogos/vulte.png',
        primary : "#F5EDF9",
        line : '#CBA3E1'
    },
    "Providus Bank" : {
        image : '/assets/BankLogos/providus.png',
        primary : "#FFFAEE",
        line : '#FEE7A9'
    },
    "Fidelity Bank" : {
        image : '/assets/BankLogos/fidelity.jpg',
        primary : "#F2FBF2",
        line : '#C0E9BD'
    },
    "Heritage Bank" : {
        image : '/assets/BankLogos/heritage.jpg',
        primary : "#F4F9F2",
        line : '#C9E3BC'
    },

    Cowrywise : {
        image : '/assets/BankLogos/cowry.png',
        primary : "#ECF4FF",
        line : '#A0C8FF'
    },
    "Polaris Bank" : {
        image : '/assets/BankLogos/polaris.png',
        primary : "#F7EFFA",
        line : '#D7B0E8'
    },

    "Unity Bank" : {
        image : '/assets/BankLogos/unity.png',
        primary : "#F7FFE7",
        line : '#D8FF85'
    },

    "Rubies Nigeria" : {
        image : '/assets/BankLogos/rubies.png',
        primary : "#FCEEEE",
        line : '#F0A8AA'
    },


    "Standard Chartered" : {
        image : '/assets/BankLogos/standardcharted.png',
        primary : "#EDFBF1",
        line : '#A4EDB7'
    },

    GTBank : {
        image : '/assets/BankLogos/gtbank.png',
        primary : "#FFF2EA",
        line : '#FFBD97'
    },



}

export default Banks;