import './css/Header.css';

import React from 'react';

const Header = () => {

    
 

    return (
        <div className = "banner">
        
        </div>
    )
}

export default Header
