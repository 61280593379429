import { Container } from "react-bootstrap";

const Footer = () => {

  const date = new Date();
  return (
    <footer>
 
        <div  className="footer-container">
          <div className="footer-logo">
            <img src="assets/images/TrakkaLogoTransparent 2.png" alt="logo" width="150px" className ="footer-logo"/>
          </div>

          <h5 className ="footer-text">© {date.getFullYear()} Finstaq Technologies</h5>
        </div>
 
    </footer>
  );
};

export default Footer;
