

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Acccount from "./pages/Acccount";

import "./App.css";
import Login from "./pages/Login";
function App() {

  

  return (
    <Router>
      <Routes>
          <Route path="/" element = { <Acccount />}/>
          <Route path="/login" element = { <Login />}/>
      </Routes>
    </Router>
  );
}

export default App;
