import React from "react";
import CategoriesMapping from "./constants/CategoriesMapping";
import "./css/Categoryitem.css";

const CategoryItem = ({ item }) => {
  const img = CategoriesMapping[item];

  return item ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: 40,
        alignItems: "center",
      }}
    >
      <div className="category-image-container">
        <img src={img} width={30} />
      </div>
      <p className="category-text">{item}</p>
    </div>
  ) : null;
};

export default CategoryItem;
