// const URL = "https://b712-197-210-78-168.ngrok.io"
// const URL = "https://trakka-staging.herokuapp.com"
const URL = "https://api.trakka.me"

export default {
    
    signup : `${URL}/accounts/sign-up/`,
    login : `${URL}/accounts/log-in-web/`,
    saveaccount : `${URL}/api/save-acct/`,
    userdetails : `${URL}/api/get-user-details/`,
    syncedAccounts : `${URL}/api/get-accounts/`,
    updateTransactions : `${URL}/api/update-transactions/`,
    getTransactions :  `${URL}/api/get-transactions/`,
    changeCategory :  `${URL}/api/set-transaction-category/`,
    removeAccount : `${URL}/api/delete-account/`,
    logout :  `${URL}/accounts/log-out/`,
    refreshAccount : `${URL}/api/refresh-account-info/`,
    updateProfile : `${URL}/accounts/update-profile/`,
    getBudget : `${URL}/api/get-budget/`,
    createBudget : `${URL}/api/create-budget/`,
    updateBudget : `${URL}/api/update-budget/`,
    removeTransactions : `${URL}/api/remove-transaction-budget/`,
    addTransactions :  `${URL}/api/add-transaction-budget/`,
    deletBudget :  `${URL}/api/delete-budget/`,
    send_otp: `${URL}/accounts/send_otp/`,
    reset_password : `${URL}/accounts/reset_password/`,
    updateAccount : `${URL}/api/update-account-balance/`,
    verifyAccount :  `${URL}/accounts/verify_email/`,
    changePassword : `${URL}/accounts/change_password/`,
    safeUrl : `${URL}/accounts/get_safe_url/`,
    summaryBudget  : `${URL}/api/upload-budget-summary/`,
    resetReauth : `${URL}/api/reset-reauth/`,
    getReauthToken : `${URL}/api/get-reauth-token/`, 
    ref : `${URL}/api/ref-origin/`,    
    test_url : `${URL}/accounts/test_safe_url/`,
    sub :   `${URL}/sub/`,
    cancelSub :   `${URL}/sub/cancel/`,
    getSub : `${URL}/sub/get-sub/`,
    activateSub : `${URL}/sub/activate/`,
    changeCard : `${URL}/sub/update/`,
}