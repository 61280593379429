import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './css/Subscription.css';
import CustomButton from './CustomButton';
import API from './data/API';
import Sync from './svgs/Sub';

const Subscription = ({
  syncedAccounts,
  userDetails,
  subscription,
  getSubscriptionActive,
}) => {
  const [disabled, setDisabled] = useState(false);


  const subscribe = async () => {
    const requestData = {
      email: userDetails.email,
      plan: '',
    };

    try {
      const plans = await axios.get(API.sub, requestData);
      var _pl = plans.data;
      requestData.plan = _pl[0].plan_id;
      const res = await axios.post(API.sub, requestData);
      var redirect = res.data.return_url;
      window.open(redirect, '_self');
    } catch (error) {
      console.log(error.response);
    }
  };

  const changeCard = async () => {
    alert("A 50 naira temporal charge will be refunded once the new card is validated")
    const requestData = {
      email: userDetails.email,
  
    };

    try {
  
      const res = await axios.post(API.changeCard, requestData);
      var redirect = res.data.return_url;
      window.open(redirect, '_self');
    } catch (error) {
      console.log(error.response);
    }
  };

  const cancelSub = async () => {
    setDisabled(true);
    try {
      const cancel = await axios.post(API.cancelSub, {
        email: userDetails.email,
      });

      await getSubscriptionActive();
    } catch (error) {
      console.log(error.response);
    } finally {
      setDisabled(false);
    }
  };

  const activateSub = async () => {
    setDisabled(true);
    try {
      const activate = await axios.post(API.activateSub, {
        email: userDetails.email,
      });

      await getSubscriptionActive();
    } catch (error) {
      console.log(error.response);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className='item-container-2'>
      <div className='title-wrapper-2'>
        <div className='icon-wrapper'>
          <Sync />
        </div>
        <p className='title'>Subscription</p>
      </div>
      <div className='component-card-2 '>
        {userDetails.is_subscribed ? (
          <>
            <p className='subtitle'>
              You’re currently on the premium tier. Sync multiple accounts and
              set custom time periods for your budgets
            </p>

            <div>
              <div className='subscription-item'>
                <p className='subscription-item-text'>Card number</p>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className='subscription-item-sub'>**** **** **** {subscription.last4}</p>
                  <p
                    className='subscription-item-sub'
                    style={{
                      textDecoration: 'underline',
                      color: '#56BD82',
                      cursor: 'pointer',
                    }}
                    onClick={() => changeCard()}
                  >
                    Change
                  </p>
                </div>
              </div>
              <br />
              {subscription.inactive ? (
                <div className='subscription-item'>
                  <p className='subscription-item-text'>
                    End of current subscription
                  </p>
                  <p className='subscription-item-sub'>
                    {subscription.next_payment_date}
                  </p>
                </div>
              ) : (
                <div className='subscription-item'>
                  <p className='subscription-item-text'>Next billing</p>
                  <p className='subscription-item-sub'>
                    {subscription.next_payment_date}
                  </p>
                </div>
              )}

              {subscription.inactive ? (
                <div className='button-view'>
                  <CustomButton
                    disabled={disabled}
                    text={'Activate Subscription'}
                    onClick={activateSub}
                  />
                </div>
              ) : (
                <div className='button-view'>
                  <CustomButton
                    text={'Cancel Subscription'}
                    color='#FF3C3C'
                    onClick={cancelSub}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <p className='subtitle'>
              You’re currently on the free tier, begin your subscription to have
              access to these features
            </p>

            <div>
              <ul>
                <li className='list-item'>Sync multiple accounts</li>
                <li className='list-item'>
                  Set custom time periods for budgets
                </li>
                <li className='list-item'>
                  Automatically refresh accounts daily
                </li>
              </ul>
            </div>
            <div className='button-view'>
              <CustomButton
                text={'Start at 500 naira monthly'}
                onClick={subscribe}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Subscription;
