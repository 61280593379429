import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className = "icon"
      fill="none"
      viewBox="0 0 50 50"
    >
      <circle cx="25" cy="25" r="25" fill="#56BD82"></circle>
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M25.165 29.963v2.317"
      ></path>
      <path
        stroke="#FAFAFA"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M19.65 36.03h11.03v-1.103a2.212 2.212 0 00-2.206-2.206h-6.617a2.212 2.212 0 00-2.206 2.206v1.102z"
      ></path>
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.548 36.03h13.235M25 29.412a7.715 7.715 0 01-7.72-7.72v-3.31a4.41 4.41 0 014.411-4.411h6.618a4.41 4.41 0 014.412 4.412v3.308A7.715 7.715 0 0125 29.411z"
      ></path>
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.798 24.614a5.358 5.358 0 01-2.129-1.324c-.993-1.102-1.654-2.426-1.654-3.97a2.73 2.73 0 012.757-2.758h.717c-.22.508-.331 1.07-.331 1.655v3.309c0 1.103.232 2.14.64 3.088zM32.202 24.614a5.357 5.357 0 002.129-1.324c.992-1.102 1.654-2.426 1.654-3.97a2.73 2.73 0 00-2.757-2.758h-.717c.22.508.33 1.07.33 1.655v3.309c0 1.103-.23 2.14-.639 3.088z"
      ></path>
    </svg>
  );
}

export default Icon;