import React from 'react'
import './css/NewButton.css'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 13 12"
      style = {{marginRight : "2px"}}
    >
      <path
        stroke="#56BD82"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M2.5 6.5h7M6 10V3"
      ></path>
    </svg>
  );
}

const NewButton = () => {
    return (
      <div className = "new-button-wrapper">
 <div className = "new-button-container">
         <Icon />
            <p className = "new-button-text">New</p>
        </div>
      </div>
       
    )
}

export default NewButton
