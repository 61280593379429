export default [
    "Bank Charges",
    "Credit",
    "Education",
    "Entertainment",
    "Food",
    "Fuel",
    "Gifts & Donations",
    "Healthcare",
    "Housing",
    "Income & Earnings",
    "Investment",
    "Loan",
    "Miscellaneous",
    "Phone and Internet",
    "Savings",
    "Shopping",
    "Subscrpition",
    "Transfer",
    "Transportation",
    "Travel",
    "Uncategorized",
    "Utilities & Bills",
    "Withdrawal",
]

