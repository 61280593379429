import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Categories from "../components/Categories";
import ChangePassword from "../components/ChangePassword";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Profile from "../components/Profile";
import Subscription from "../components/Subscription";
import SyncedAccounts from "../components/SyncedAccounts";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import API from "../components/data/API";
import LoadingSpin from "react-loading-spin";

const Acccount = () => {
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();

  const uid = searchParams.get("user");
  const token = searchParams.get("token");
  const liveChat = searchParams.get("live_chat");
  const tl = parseInt(searchParams.get("tl"));

  const now = Date.now();

  const [openChat, setOpenChat] = useState(liveChat === "true");

  const [userDetails, setUserDetails] = useState({});
  const [syncedAccounts, setSyncedAccounts] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [loading, setLoading] = useState(true);

  const getAccounts = async () => {
    try {
      const res = await axios.get(API.syncedAccounts);

      setSyncedAccounts(res.data);
    } catch (er) {
      console.log(er);
    }
  };

  const getUserDetails = async () => {
    try {
      const res = await axios.get(API.userdetails);

      setUserDetails(res.data);

      setUpFreshChat(res.data);

      if (res.data.is_subscribed) {
        getSubscriptionActive();
      }
    } catch (er) {
      console.log(er);
    }
  };

  const getSubscriptionActive = async () => {
    try {
      const sub = await axios.get(API.getSub);

      setSubscription(sub.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const checkAuth = async () => {
    const requestData = {
      uid: uid,
      token: token,
    };

    try {
      const res = await axios.post(API.test_url, requestData);
      const token = res.data[0];

      axios.defaults.headers.common["Authorization"] = `Token ${token}`;

      await getUserDetails();
      await getAccounts();

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (e) {
      navigate("/login");
      console.log(e.response.data);
    }
  };

  const setUpFreshChat = (user) => {
    

    window.fcWidget.init({
      token: "904c49c4-7aca-4220-b208-9b57634521b0",
      host: "https://wchat.freshchat.com",
      open: openChat,
      config: {
        showFAQOnOpen: true,
        hideFAQ: false,
        headerProperty: {
          //If you have multiple sites you can use the appName and appLogo to overwrite the values.
          appName: "Support",
        },
        headers: {
          chat: "How can we be of service ?",
          chat_help: "Reach out to us if you have any questions",
        },
      },
   

      firstName: user.firstname, // user's first name
      lastName: user.lastname, // user's last name
      email: user.email, // user's email address
      phone: user.phone, // phone number without country code
      phoneCountryCode: user.phoneCountryCode, // phone's country code
      "plan": user.plan, // user's meta property 1
      "status": user.status,
      externalId : user.email,
      restoreId: user.restoreID ? user.restoreID : null,
    });

    // window.fcWidget.user.get(function (resp) {
    //   var status = resp && resp.status,
    //     data = resp && resp.data;
    //   if (status !== 200) {
    //     window.fcWidget.user.setProperties({
    //       firstName: user.firstName, // user's first name
    //       lastName: user.lastName, // user's last name
    //       email: user.email, // user's email address
    //       phone: user.phone, // phone number without country code
    //       phoneCountryCode: user.phoneCountryCode, // phone's country code
    //       plan: user.plan, // user's meta property 1
    //       status: user.status, // user's meta property 2
    //     });
    //     window.fcWidget.on("user:created", function (resp) {
    //       var status = resp && resp.status,
    //         data = resp && resp.data;
    //       if (status === 200) {
    //         if (data.restoreId) {
    //           // Update restoreId in your database
    //         }
    //       }
    //     });
    //   }
    // });
  };

  useEffect(() => {
    const diff = now - tl;
    // !TO DO Change back

    if (diff < 600000) {
      checkAuth();
  
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {


    window.fcWidget.user.setProperties({
      firstName: userDetails.firstname,
      lastName: userDetails.lastname, // user's last name
      email: userDetails.email, // user's email address
     
    });
    
  }, [userDetails])

  return (
    <div className="App">
      {!loading ? (
        <>
          {" "}
          <Header />
          <Container className="content-body">
            <Row>
              <Col xl={7} md={12}>
                <SyncedAccounts accounts={syncedAccounts} />
              </Col>
              <Col xl={5} md={12}>
                <Subscription
                  userDetails={userDetails}
                  syncedAccounts={syncedAccounts}
                  getSubscriptionActive={getSubscriptionActive}
                  subscription={subscription}
                />
              </Col>
            </Row>

            <Row>
              <Col xl={6} md={12}>
                <Profile userDetails={userDetails} />
              </Col>
              <Col xl={6} md={12}>
                <ChangePassword />
              </Col>
            </Row>

            <Row>
              <Categories />
            </Row>
            <Footer />
          </Container>
        </>
      ) : (
        <>
          <Header />
          <Container
            style={{
              paddingTop: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingSpin primaryColor="#56bd82" />
          </Container>
        </>
      )}
    </div>
  );
};

export default Acccount;
