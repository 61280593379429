import React from "react";
import AccountItem from "./AccountItem";
import "./css/SyncedAccounts.css";
import Sync from "./svgs/Sync";

import TempAccounts from "./data/SyncedAccountDummy";
import NewButton from "./NewButton";

const SyncedAccounts = ({accounts}) => {
  return (
    <div className="item-container">
      <div className="title-wrapper">
        <div className="icon-wrapper">
          <Sync />
        </div>
        <p className="title">Synced Accounts</p>
      </div>
      <div className="component-card">
        <p className="subtitle">
          These are the bank accounts you have synced on Trakka </p>

        <div style={{overflowY : 'auto'  }} className = 'component-card-body' >
          {accounts.map((e, i) => (
            <AccountItem account={e} key = {i}/>
          ))}
        </div>
{/* 
        <NewButton /> */}
      </div>
    </div>
  );
};

export default SyncedAccounts;
